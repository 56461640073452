.close {
  color: white;
  position: absolute;
  top: 20px;
  right: 20px;
  height: 40px;
  width: 40px;
  transition: opacity 0.3s ease;
}

.close:hover {
  opacity: 0.8
}