html, body, #root {
  height: 100%;
}

body {
  font-family: Futura, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #182f33;
  background-image: url("background.png");   
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover, a:focus, a:visited
{
   color: inherit;
   text-decoration:none; 
   cursor:pointer;  
}